
.carouselTicker {
  overflow: hidden;
  float: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.carouselTicker__list {
  margin: 10px 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
}

.carouselTicker__item {
  margin: 0;
  float: left;
  border: 4px solid #fbb714;
  background-color:#fbb714;
  margin-left: 5px;
  width: 101px;
  height: 40px;
  color:#fff;
  line-height: 34px;
  text-align: center;
}
