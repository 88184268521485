/* line 27, bootstrap-select.scss */
select.bs-select-hidden,
select.selectpicker {
  /* display: none !important; */
}

/* line 32, bootstrap-select.scss */
.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/
}
/* line 37, bootstrap-select.scss */
.bootstrap-select.btn-group > .dropdown-toggle {
  height: 100%;
}
/* line 43, bootstrap-select.scss */
.bootstrap-select > .dropdown-toggle {
  width: 100%;
  padding-right: 25px;
  z-index: 1;
}
/* line 48, bootstrap-select.scss */
.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: #999;
}
/* line 56, bootstrap-select.scss */
.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
}
/* line 67, bootstrap-select.scss */
.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2;
}
/* line 77, bootstrap-select.scss */
.has-error .bootstrap-select .dropdown-toggle, .error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48;
}
/* line 82, bootstrap-select.scss */
.bootstrap-select.fit-width {
  width: auto !important;
}
/* line 86, bootstrap-select.scss */
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px;
}
/* line 90, bootstrap-select.scss */
.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}

/* line 97, bootstrap-select.scss */
.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
}
/* line 102, bootstrap-select.scss */
.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}
/* line 106, bootstrap-select.scss */
.bootstrap-select.form-control.input-group-btn {
  z-index: auto;
}
/* line 110, bootstrap-select.scss */
.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

/* line 119, bootstrap-select.scss */
.bootstrap-select.btn-group:not(.input-group-btn), .bootstrap-select.btn-group[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0;
}
/* line 130, bootstrap-select.scss */
.bootstrap-select.btn-group.dropdown-menu-right, .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right, .row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right;
}
/* line 135, bootstrap-select.scss */
.form-inline .bootstrap-select.btn-group, .form-horizontal .bootstrap-select.btn-group, .form-group .bootstrap-select.btn-group {
  margin-bottom: 0;
}
/* line 141, bootstrap-select.scss */
.form-group-lg .bootstrap-select.btn-group.form-control, .form-group-sm .bootstrap-select.btn-group.form-control {
  padding: 0;
}
/* line 145, bootstrap-select.scss */
.form-group-lg .bootstrap-select.btn-group.form-control .dropdown-toggle, .form-group-sm .bootstrap-select.btn-group.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit;
}
/* line 155, bootstrap-select.scss */
.form-inline .bootstrap-select.btn-group .form-control {
  width: 100%;
}
/* line 159, bootstrap-select.scss */
.bootstrap-select.btn-group.disabled,
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
}
/* line 163, bootstrap-select.scss */
.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important;
}
/* line 168, bootstrap-select.scss */
.bootstrap-select.btn-group.bs-container {
  position: absolute;
  height: 0 !important;
  padding: 0 !important;
}
/* line 173, bootstrap-select.scss */
.bootstrap-select.btn-group.bs-container .dropdown-menu {
  z-index: 1060;
}
/* line 180, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
}
/* line 187, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle;
}
/* line 196, bootstrap-select.scss */
.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
  width: 100%;
}
/* line 201, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* line 205, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-menu.inner {
  display: block;
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
}
/* line 216, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-menu a.dropdown-item {
  position: relative;
  cursor: pointer;
  user-select: none;
}
/* line 221, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-menu a.dropdown-item.active small {
  color: #fff;
}
/* line 225, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-menu a.dropdown-item.disabled a {
  cursor: not-allowed;
}
/* line 229, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-menu a.dropdown-item.hidden {
  display: none;
}
/* line 233, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-menu a.dropdown-item span.dropdown-item-inner {
  display: block;
}
/* line 236, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-menu a.dropdown-item span.dropdown-item-inner.opt {
  position: relative;
  padding-left: 2.25em;
}
/* line 241, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-menu a.dropdown-item span.dropdown-item-inner span.check-mark {
  display: none;
}
/* line 245, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-menu a.dropdown-item span.dropdown-item-inner span.text {
  display: inline-block;
}
/* line 250, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-menu a.dropdown-item small {
  padding-left: 0.5em;
}
/* line 257, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-menu .dropdown-item .span.check-mark {
  display: none;
}
/* line 261, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-menu .dropdown-item .span.text {
  display: inline-block;
}
/* line 266, bootstrap-select.scss */
.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: whitesmoke;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* line 282, bootstrap-select.scss */
.bootstrap-select.btn-group .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap;
}
/* line 290, bootstrap-select.scss */
.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
  position: static;
}
/* line 294, bootstrap-select.scss */
.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}
/* line 302, bootstrap-select.scss */
.bootstrap-select.btn-group.show-tick .dropdown-menu a.selected span.dropdown-item-inner span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  margin-top: 5px;
}
/* line 309, bootstrap-select.scss */
.bootstrap-select.btn-group.show-tick .dropdown-menu a a span.text {
  margin-right: 34px;
}

/* line 316, bootstrap-select.scss */
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
  z-index: 1061;
}
/* line 321, bootstrap-select.scss */
.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none;
}
/* line 332, bootstrap-select.scss */
.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none;
}
/* line 345, bootstrap-select.scss */
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0;
}
/* line 352, bootstrap-select.scss */
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid white;
  border-bottom: 0;
}
/* line 361, bootstrap-select.scss */
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
  right: 12px;
  left: auto;
}
/* line 366, bootstrap-select.scss */
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
  right: 13px;
  left: auto;
}
/* line 373, bootstrap-select.scss */
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before, .bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
  display: block;
}

/* line 380, bootstrap-select.scss */
.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px;
}

/* line 386, bootstrap-select.scss */
.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* line 390, bootstrap-select.scss */
.bs-actionsbox .btn-group button {
  width: 50%;
}

/* line 395, bootstrap-select.scss */
.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* line 400, bootstrap-select.scss */
.bs-donebutton .btn-group button {
  width: 100%;
}

/* line 406, bootstrap-select.scss */
.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px;
}
/* line 410, bootstrap-select.scss */
.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none;
}
/* line 417, bootstrap-select.scss */
.input-group .bs-searchbox .form-control {
  width: 100%;
}
