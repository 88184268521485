/* jQuery Countdown styles 2.0.0. */
.countdown-rtl {
  direction: rtl; }

.countdown-holding span {
  color: #888; }

.countdown-row {
  width: 100%;
  text-align: center; }
  .countdown-row:after {
    content: '';
    display: table;
    clear: both; }

.countdown-show1 .countdown-section {
  width: calc(100% - 20px); }

.countdown-show2 .countdown-section {
  width: calc(50% - 20px); }

.countdown-show3 .countdown-section {
  width: calc(33.3% - 20px); }

.countdown-show4 .countdown-section {
  width: calc(25% - 20px); }

.countdown-show5 .countdown-section {
  width: calc(20% - 20px); }

.countdown-show6 .countdown-section {
  width: calc(16.66% - 20px); }

.countdown-show7 .countdown-section {
  width: calc(14.285% - 20px); }

.countdown-section {
  position: relative;
  display: block;
  float: left;
  font-size: 75%;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px; }

.countdown-amount {
  font-size: 200%; }

.countdown-period {
  display: block; }

.countdown-descr {
  display: block;
  width: 100%; }

.countdown-separator .countdown-section:not(:last-child):after {
  content: ':';
  display: inline-block;
  font-size: 30px;
  line-height: 1;
  position: absolute;
  left: 100%;
  margin-left: 8px;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%); }

/*# sourceMappingURL=jquery.countdown.css.map */
